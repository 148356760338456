/**
 * UAT Environment properties file.
 */
export const environment = {
    production: false
};

/**
 * Application URLs.
 */
export const APP_URL = {
    API: 'https://learnuat.uniathena.com/athenauat/api/',
    API_NEW: 'https://learnuat.uniathena.com/athenaapi/api/',
    IP_GEOLOCATION: 'https://extreme-ip-lookup.com/json/?key=Q2XTHdI7x9BFavSEj8pj',
    OLD_LMS: 'https://ulearn.schneidestaging.in/',
    WEBSITE: 'https://uniathena.com/',
    NEW_LMS: 'https://dev.uniathena.com/lms/',
    ACADS_API: 'https://athenaapi.schneidestaging.in/',
    WEBSITE_ENCRYPT_API: 'https://athenaapi.uniathena.com/Encript/GetEncriptValue/',
    BASE_HREF_URL: 'https://uat.uniathena.com'
};

/**
 * Application configurations.
 */
export const APP_CONFIGURATION = {
    ADMIN: 24,
    CATEGORY_ID: 247,
    DISCUSSION_BOARD_ADMIN: 22,
    GUEST: 6
};

/**
 * Social login client Ids.
 */
export const SOCIAL_LOGIN = {
    GOOGLE_PROVIDER_ID: '276645629606-l0lte3d7aag43kmsm8g339rhhrhaev2o.apps.googleusercontent.com',
    FACEBOOK_PROVIDER_ID: '340564521258908',
    MICROSOFT_CLIENT_ID: 'e9314991-e6e5-42e2-963f-f47461987334'
};
  


